






import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'AdminView',
  components: {}

})
export default class AdminView extends Vue {

}
